.footer {
  grid-area: footer;

  border-top: 2px solid rgba(122, 122, 122, 0.8);
  border-bottom: 2px solid rgba(122, 122, 122, 0.8);
  @include title-gradient;

  display: flex;
  justify-content: space-between;

  a {
    color: inherit;
  }

  h5 {
    margin: auto;
  }

  @include multi-column-layout {
    h5 {
      padding: 15px 0 15px 25px;
      // margin: 25px 0 0 23px;
      // max-height: 10px;
    }
  }

  @include single-column-layout {
    h5 {
      padding: 15px 0 15px 10px;
      margin-left: 0;
    }

    #footer-links {
      display: none;
    }
  }
}
