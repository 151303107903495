.contact-section {
  #contact-form {
    font-size: 1rem;

    #contact-user-details {
      display: flex;

      input {
        padding: 5px;
        margin: 10px 0 10px 0;
      }
    }
    #contact-user-message {
      textarea {
        padding: 5px;
        margin: 10px 0 10px 0;
        width: 100%;
      }
    }
  }

  h1 {
    padding: 20px 0 20px 0;
    // margin-left: 5px;
  }
  padding: 10px;
  margin: 5px 5px 10px 5px;

  #contact-submit-button {
    background-color: $complementary-blue;
    margin-top: 0.5rem;
  }

  margin: 2rem 0 2rem 0;

  @include multi-column-layout {
    padding: 10px 15px 10px 15px;
    p {
      font-size: 1.1rem;
    }
    #contact-user-details {
      justify-content: space-between;
      input {
        width: 49%;
      }
    }
  }

  @include single-column-layout {
    padding: 10px;
    margin: 5px 5px 10px 5px;
    p {
      font-size: 1.2rem;
    }

    #contact-form {
      #contact-user-details {
        flex-direction: column;
        input {
          input {
            padding: 5px;
            margin: 10px 0 10px 0;
            width: 100%;
          }
        }
      }
    }
  }
}
