.header-text {
  grid-area: header-text;

  margin-top: 20px;
  padding: 30px;
  font-size: 1.6rem;

  @include single-column-layout {
    margin-top: 20px;
    padding: 15px;
    font-size: 1.3rem;
  }
}
