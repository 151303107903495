@keyframes scaleButtonUp {
  0% {
    transform: scale(1);

    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.3);

    -webkit-transform: scale(1.3);
  }
}

@keyframes scaleButtonUp-Hover {
  0% {
    transform: scale(1);

    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.19);

    -webkit-transform: scale(1.19);
  }
}

@keyframes scaleButtonDown {
  0% {
    transform: scale(1);

    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(0.8);

    -webkit-transform: scale(0.8);
  }
}
