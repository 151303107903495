@mixin multi-column-layout {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

/* Extra small devices (phones, 600px and down) */
@mixin single-column-layout {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin tablet-layout {
  @media only screen and (min-width: 600px) and (max-width: 920px) {
    @content;
  }
}
