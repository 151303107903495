.project-search-bar {
  @import "../animation/scaleButton";
  @import "../animation/closeButtonFadeIn";
  @include title-gradient;

  display: none;
  @include multi-column-layout {
    margin: 0.75rem 0;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;

    &__cell {
      display: flex;
      margin: 1rem;
      padding: 0 0.5rem;
    }

    &__image {
      height: 80px;
      width: 80px;
      margin: 0.5rem;
      justify-self: center;

      // padding: 0.5rem;
      &:hover {
        cursor: pointer;

        opacity: 0.5;
      }
      &--active {
        animation: scaleButtonUp 1s cubic-bezier(0, 0.79, 0.6, 0.5) forwards;
        -webkit-animation: scaleButtonUp 1s cubic-bezier(0, 0.79, 0.6, 0.5)
          forwards;
        &:hover {
          opacity: 1;
        }
      }
      &--inactive {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
        animation: scaleButtonDown 1s cubic-bezier(0.22, 1, 0.36, 1) forwards;
        -webkit-animation: scaleButtonDown 1s cubic-bezier(0.22, 1, 0.36, 1)
          forwards;
      }
      &--no-selected {
        &:hover {
          animation: scaleButtonUp-Hover 1s cubic-bezier(0, 0.79, 0.6, 0.5)
            forwards;
          -webkit-animation: scaleButtonUp-Hover 1s
            cubic-bezier(0, 0.79, 0.6, 0.5) forwards;
        }
      }
    }

    .ant-btn {
      animation: closeButtonFadeIn ease 1.2s;
      -webkit-animation: closeButtonFadeIn ease 1.2s;
      -moz-animation: closeButtonFadeIn ease 1.2s;
      -o-animation: closeButtonFadeIn ease 1.2s;
      -ms-animation: closeButtonFadeIn ease 1.2s;

      margin-top: 0.75rem;
      right: 30px;
      z-index: 4;
      .anticon-close {
        svg {
          margin-top: -7px;
        }
      }
    }
  }
}
