.project-card {
  h3 {
    color: black;
    font-size: 24pt;
  }

  .main-content {
    display: flex;
    flex-direction: column;

    &__tech-stack {
      display: flex;
      justify-content: center;
      padding: 0.5rem 0;
      img {
        max-height: 40px;
        max-width: 40px;
      }
    }
  }

  .coloured-title-stripe {
    @include title-gradient;
    opacity: 0.8;
    padding: 15px;

    h3 {
      margin-left: 5px;
    }
  }
  .no-title-effect {
    transition: color 1s ease-out;
    color: black;
  }
  .title-effect {
    transition: opacity 1s linear;
    opacity: 0.7;
  }
  hr {
    margin-top: 6px;
  }
  .project-image {
    margin-top: 5px;
    margin-bottom: 15px;
    transition: box-shadow 0.75s ease-out;
    box-shadow: 7px 7px 5px rgba(192, 192, 192, 0.8);
    &:hover {
      transition: box-shadow 0.75s ease-in;
      box-shadow: 10px 10px 5px rgba(122, 122, 122, 0.8);
    }
  }

  .hiddenProjectContainer {
    p {
      padding: 5px;
    }
  }
}

@include multi-column-layout {
  .project-card {
    margin: 0 10px 0 10px;
    padding: 20px;

    .coloured-title-stripe {
      box-shadow: 7px 7px 5px rgba(192, 192, 192, 0.8);
    }

    .project-image {
      margin-top: 20px;
    }

    .hiddenProjectContainer,
    hr {
      display: none;
    }
  }
}

@include single-column-layout {
  .project-card {
    margin-top: 0.5rem;
    .main-content {
      padding: 15px;
      margin: 0 1rem;
      p {
        font-size: 1.2rem;
      }
      .project-image {
        margin: 5px 0;
      }
    }

    // .hiddenSingleColumn {
    //   display: none;
    // }
    .hiddenProjectContainer {
      margin-bottom: -10px;
    }
  }
}
