.LinkSection {
  @include multi-column-layout {
    display: grid;
    padding: 20px;
    // Add more columns if more links required
    grid-template-columns: repeat(4, 25%);

    img {
      width: 100%;
      padding: 30px;
      margin: 0;
      // margin: 30px 0 30px 0;
      height: 400px;
      transition: height 0.75s ease-out;

      &:hover {
        animation: borderFadeIn .7s cubic-bezier(0.895, 0.03, .67, 0.10) 1 forwards normal;
      }
    }
  }

  @include single-column-layout {
    margin-bottom: 50px;
    img {
      width: 100%;
      padding: 20px;
      //   Enlarge effect when hovering over image
      transition: height 0.75s ease-out;
      height: 300px;
     
    }
    // Different color for every odd tile
    .link-tile:nth-child(odd) {
      width: 100%;
      padding: 50px;
      margin: 0 0 50px 0;
      background-color: $subtle-grey;
    }
    // Fixes issue with linkedin tile bottom margin
    #link-tile-1 {
      margin-bottom: 50px;
    }
  }
}

// Fixes footer bug in multi-column view on this specific page, due to being before footer page in index.scss
// @include multi-column-layout {
//   .footer {
//     height: 60px;
//     margin: 5px 0 -55px 0;
//   }
// }
