@keyframes borderFadeIn {
    0%{
        // border: none;
        opacity: 1;
    }
    60%{
        -webkit-transform: rotate(360deg); 
    }
    65%{
        -webkit-transform: translateY(-150px);
       
    }
    85%{
        -webkit-transform: translateY(0); 
    }
    95%{
        -webkit-transform: translateY(-50px);
    }
    100%{
        // border: 2px solid black;
        
        opacity: .7;
    }
}