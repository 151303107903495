.about-section {
  grid-area: main-content;

  #about-title {
    grid-area: about-title;
  }

  #profile-div {
    grid-area: profile-div;
    display: flex;
    flex-direction: column;
  }

  #about-content-div {
    grid-area: about-content-div;
  }

  #about-title {
    padding: 20px 0 20px 5px;
    margin: 10px 0 10px 30px;
  }

  #skills-list {
    display: grid;
    list-style: none;

    li::before {
      content: "";
      height: 0.4em;
      width: 0.4em;
      @include title-gradient;
      display: inline-block;
      position: relative;
      transform: rotate(45deg);
      padding: 2px;
      margin: 10px 10px 5px 0;
    }
  }
}

@include multi-column-layout {
  .about-section {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, auto);
    gap: 5px;
    grid-template-areas:
      "about-title about-title"
      "profile-div about-content-div";
  }

  #profile-div {
    padding: 20px;
    h3 {
      align-self: center;
      text-decoration: underline;
      padding: 10px;
    }

    #skills-list {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 35px;
    }
  }

  #about-content-div {
    padding: 20px;
  }
}

@include single-column-layout {
  .about-section {
    #profile-div {
      margin: 5px 5px 10px 0;

      h3 {
        align-self: center;
        text-decoration: underline;
        padding: 10px;
      }
      #profile-image {
        height: 250px;
        margin: 20px 15px 20px 15px;

        //   Option to make profile image black & white
        //   -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        //   filter: grayscale(100%);
      }
    }
    #skills-list {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      font-size: 1.2rem;
    }
  }

  #about-content-div {
    padding: 10px;
    margin: 5px 5px 10px 5px;
    p {
      font-size: 1.2rem;
    }
  }
}
