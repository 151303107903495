.landing-page {
  .more-projects {
    grid-area: more-projects-button;
  }
  display: grid;
  grid-template-areas:
    "header-text"
    "project-div"
    "more-projects-button";

  // For only the projectDiv in the landing page it first sets display to none to hide them
  .project-card {
    display: none;
  }
  // Then on only the first two cards sets diplay to visible
  .project-card:nth-child(-n + 2) {
    display: block;
  }

  .more-projects {
    display: flex;
    justify-content: center;

    margin: 0.5rem 0;

    &__button {
      width: 400px;
    }
  }

  &__contact-section {
    background-color: rgba($main-orange, 0.7);
    margin: 2rem 1.2rem 2.2rem 1.2rem;
    padding: 0.5rem;
    border-radius: 10px;
  }

  @include single-column-layout {
    .more-projects {
      &__button {
        width: 200px;
      }
    }
  }
}
