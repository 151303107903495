.App {
  min-height: 100vh;
  width: 100%;
  display: grid;
  font-family: $montserrat;

  background-color: #fdfcfc;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 60px;
  grid-template-areas:
    "header"
    "main-content"
    "footer";

  a:hover {
    text-decoration: none;
  }
}
