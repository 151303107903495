@import url(https://fonts.googleapis.com/css?family=Montserrat|Satisfy&display=swap);
/* Extra small devices (phones, 600px and down) */
@-webkit-keyframes borderFadeIn {
  0% {
    opacity: 1; }
  60% {
    -webkit-transform: rotate(360deg); }
  65% {
    -webkit-transform: translateY(-150px); }
  85% {
    -webkit-transform: translateY(0); }
  95% {
    -webkit-transform: translateY(-50px); }
  100% {
    opacity: .7; } }
@keyframes borderFadeIn {
  0% {
    opacity: 1; }
  60% {
    -webkit-transform: rotate(360deg); }
  65% {
    -webkit-transform: translateY(-150px); }
  85% {
    -webkit-transform: translateY(0); }
  95% {
    -webkit-transform: translateY(-50px); }
  100% {
    opacity: .7; } }

.header-image {
  grid-area: header-image;
  background-color: #ff9900; }
  .header-image #header-image {
    z-index: -1;
    opacity: 0.6; }

.landing-page {
  display: grid;
  grid-template-areas: "header-text" "project-div" "more-projects-button"; }
  .landing-page .more-projects {
    grid-area: more-projects-button; }
  .landing-page .project-card {
    display: none; }
  .landing-page .project-card:nth-child(-n + 2) {
    display: block; }
  .landing-page .more-projects {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0; }
    .landing-page .more-projects__button {
      width: 400px; }
  .landing-page__contact-section {
    background-color: rgba(255, 153, 0, 0.7);
    margin: 2rem 1.2rem 2.2rem 1.2rem;
    padding: 0.5rem;
    border-radius: 10px; }
  @media only screen and (max-width: 600px) {
    .landing-page .more-projects__button {
      width: 200px; } }

.header-text {
  grid-area: header-text;
  margin-top: 20px;
  padding: 30px;
  font-size: 1.6rem; }
  @media only screen and (max-width: 600px) {
    .header-text {
      margin-top: 20px;
      padding: 15px;
      font-size: 1.3rem; } }

.project-div {
  margin: 1rem 0 1rem 0; }
  @media only screen and (min-width: 600px) {
    .project-div {
      display: grid;
      grid-template-columns: repeat(2, 1fr); } }

.project-card h3 {
  color: black;
  font-size: 24pt; }

.project-card .main-content {
  display: flex;
  flex-direction: column; }
  .project-card .main-content__tech-stack {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0; }
    .project-card .main-content__tech-stack img {
      max-height: 40px;
      max-width: 40px; }

.project-card .coloured-title-stripe {
  background: linear-gradient(rgba(251, 255, 19, 0.5), rgba(251, 255, 19, 0)), linear-gradient(to top left, rgba(211, 26, 26, 0.8), rgba(211, 26, 26, 0)), linear-gradient(to bottom right, rgba(218, 165, 32, 0.4), rgba(218, 165, 32, 0)), linear-gradient(to top right, #ff9900, rgba(255, 153, 0, 0));
  background-blend-mode: lighten;
  opacity: 0.8;
  padding: 15px; }
  .project-card .coloured-title-stripe h3 {
    margin-left: 5px; }

.project-card .no-title-effect {
  transition: color 1s ease-out;
  color: black; }

.project-card .title-effect {
  transition: opacity 1s linear;
  opacity: 0.7; }

.project-card hr {
  margin-top: 6px; }

.project-card .project-image {
  margin-top: 5px;
  margin-bottom: 15px;
  transition: box-shadow 0.75s ease-out;
  box-shadow: 7px 7px 5px rgba(192, 192, 192, 0.8); }
  .project-card .project-image:hover {
    transition: box-shadow 0.75s ease-in;
    box-shadow: 10px 10px 5px rgba(122, 122, 122, 0.8); }

.project-card .hiddenProjectContainer p {
  padding: 5px; }

@media only screen and (min-width: 600px) {
  .project-card {
    margin: 0 10px 0 10px;
    padding: 20px; }
    .project-card .coloured-title-stripe {
      box-shadow: 7px 7px 5px rgba(192, 192, 192, 0.8); }
    .project-card .project-image {
      margin-top: 20px; }
    .project-card .hiddenProjectContainer,
    .project-card hr {
      display: none; } }

@media only screen and (max-width: 600px) {
  .project-card {
    margin-top: 0.5rem; }
    .project-card .main-content {
      padding: 15px;
      margin: 0 1rem; }
      .project-card .main-content p {
        font-size: 1.2rem; }
      .project-card .main-content .project-image {
        margin: 5px 0; }
    .project-card .hiddenProjectContainer {
      margin-bottom: -10px; } }

.about-section {
  grid-area: main-content; }
  .about-section #about-title {
    grid-area: about-title; }
  .about-section #profile-div {
    grid-area: profile-div;
    display: flex;
    flex-direction: column; }
  .about-section #about-content-div {
    grid-area: about-content-div; }
  .about-section #about-title {
    padding: 20px 0 20px 5px;
    margin: 10px 0 10px 30px; }
  .about-section #skills-list {
    display: grid;
    list-style: none; }
    .about-section #skills-list li::before {
      content: "";
      height: 0.4em;
      width: 0.4em;
      background: linear-gradient(rgba(251, 255, 19, 0.5), rgba(251, 255, 19, 0)), linear-gradient(to top left, rgba(211, 26, 26, 0.8), rgba(211, 26, 26, 0)), linear-gradient(to bottom right, rgba(218, 165, 32, 0.4), rgba(218, 165, 32, 0)), linear-gradient(to top right, #ff9900, rgba(255, 153, 0, 0));
      background-blend-mode: lighten;
      display: inline-block;
      position: relative;
      transform: rotate(45deg);
      padding: 2px;
      margin: 10px 10px 5px 0; }

@media only screen and (min-width: 600px) {
  .about-section {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, auto);
    grid-gap: 5px;
    gap: 5px;
    grid-template-areas: "about-title about-title" "profile-div about-content-div"; }
  #profile-div {
    padding: 20px; }
    #profile-div h3 {
      align-self: center;
      text-decoration: underline;
      padding: 10px; }
    #profile-div #skills-list {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 35px; }
  #about-content-div {
    padding: 20px; } }

@media only screen and (max-width: 600px) {
  .about-section #profile-div {
    margin: 5px 5px 10px 0; }
    .about-section #profile-div h3 {
      align-self: center;
      text-decoration: underline;
      padding: 10px; }
    .about-section #profile-div #profile-image {
      height: 250px;
      margin: 20px 15px 20px 15px; }
  .about-section #skills-list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
    font-size: 1.2rem; }
  #about-content-div {
    padding: 10px;
    margin: 5px 5px 10px 5px; }
    #about-content-div p {
      font-size: 1.2rem; } }

.contact-section {
  padding: 10px;
  margin: 5px 5px 10px 5px;
  margin: 2rem 0 2rem 0; }
  .contact-section #contact-form {
    font-size: 1rem; }
    .contact-section #contact-form #contact-user-details {
      display: flex; }
      .contact-section #contact-form #contact-user-details input {
        padding: 5px;
        margin: 10px 0 10px 0; }
    .contact-section #contact-form #contact-user-message textarea {
      padding: 5px;
      margin: 10px 0 10px 0;
      width: 100%; }
  .contact-section h1 {
    padding: 20px 0 20px 0; }
  .contact-section #contact-submit-button {
    background-color: #0066ff;
    margin-top: 0.5rem; }
  @media only screen and (min-width: 600px) {
    .contact-section {
      padding: 10px 15px 10px 15px; }
      .contact-section p {
        font-size: 1.1rem; }
      .contact-section #contact-user-details {
        justify-content: space-between; }
        .contact-section #contact-user-details input {
          width: 49%; } }
  @media only screen and (max-width: 600px) {
    .contact-section {
      padding: 10px;
      margin: 5px 5px 10px 5px; }
      .contact-section p {
        font-size: 1.2rem; }
      .contact-section #contact-form #contact-user-details {
        flex-direction: column; }
        .contact-section #contact-form #contact-user-details input input {
          padding: 5px;
          margin: 10px 0 10px 0;
          width: 100%; } }

@media only screen and (min-width: 600px) {
  .LinkSection {
    display: grid;
    padding: 20px;
    grid-template-columns: repeat(4, 25%); }
    .LinkSection img {
      width: 100%;
      padding: 30px;
      margin: 0;
      height: 400px;
      transition: height 0.75s ease-out; }
      .LinkSection img:hover {
        -webkit-animation: borderFadeIn 0.7s cubic-bezier(0.895, 0.03, 0.67, 0.1) 1 forwards normal;
                animation: borderFadeIn 0.7s cubic-bezier(0.895, 0.03, 0.67, 0.1) 1 forwards normal; } }

@media only screen and (max-width: 600px) {
  .LinkSection {
    margin-bottom: 50px; }
    .LinkSection img {
      width: 100%;
      padding: 20px;
      transition: height 0.75s ease-out;
      height: 300px; }
    .LinkSection .link-tile:nth-child(odd) {
      width: 100%;
      padding: 50px;
      margin: 0 0 50px 0;
      background-color: #f1f3f4; }
    .LinkSection #link-tile-1 {
      margin-bottom: 50px; } }

.header {
  grid-area: header; }
  .header nav {
    background: linear-gradient(rgba(251, 255, 19, 0.5), rgba(251, 255, 19, 0)), linear-gradient(to top left, rgba(211, 26, 26, 0.8), rgba(211, 26, 26, 0)), linear-gradient(to bottom right, rgba(218, 165, 32, 0.4), rgba(218, 165, 32, 0)), linear-gradient(to top right, #ff9900, rgba(255, 153, 0, 0));
    background-blend-mode: lighten;
    padding: 20px; }
    .header nav .nav-links {
      margin: 15px 0 8px 13px; }
  .header a {
    font-family: "Satisfy", cursive;
    font-size: 18pt;
    padding-top: 5px; }
    .header a:hover {
      opacity: 0.4; }
  .header #navbar-brand img {
    height: 90px;
    width: 200px; }
  @media only screen and (min-width: 600px) {
    .header .navbar-nav {
      margin-left: 30px; }
    .header .nav-links {
      display: flex; }
      .header .nav-links .navLink {
        padding: 0 5px 0 5px;
        margin-left: 3px; } }

.footer {
  grid-area: footer;
  border-top: 2px solid rgba(122, 122, 122, 0.8);
  border-bottom: 2px solid rgba(122, 122, 122, 0.8);
  background: linear-gradient(rgba(251, 255, 19, 0.5), rgba(251, 255, 19, 0)), linear-gradient(to top left, rgba(211, 26, 26, 0.8), rgba(211, 26, 26, 0)), linear-gradient(to bottom right, rgba(218, 165, 32, 0.4), rgba(218, 165, 32, 0)), linear-gradient(to top right, #ff9900, rgba(255, 153, 0, 0));
  background-blend-mode: lighten;
  display: flex;
  justify-content: space-between; }
  .footer a {
    color: inherit; }
  .footer h5 {
    margin: auto; }
  @media only screen and (min-width: 600px) {
    .footer h5 {
      padding: 15px 0 15px 25px; } }
  @media only screen and (max-width: 600px) {
    .footer h5 {
      padding: 15px 0 15px 10px;
      margin-left: 0; }
    .footer #footer-links {
      display: none; } }

.project-search-bar {
  background: linear-gradient(rgba(251, 255, 19, 0.5), rgba(251, 255, 19, 0)), linear-gradient(to top left, rgba(211, 26, 26, 0.8), rgba(211, 26, 26, 0)), linear-gradient(to bottom right, rgba(218, 165, 32, 0.4), rgba(218, 165, 32, 0)), linear-gradient(to top right, #ff9900, rgba(255, 153, 0, 0));
  background-blend-mode: lighten;
  display: none; }

@-webkit-keyframes scaleButtonUp {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3); } }

@keyframes scaleButtonUp {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3); } }

@-webkit-keyframes scaleButtonUp-Hover {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(1.19);
    -webkit-transform: scale(1.19); } }

@keyframes scaleButtonUp-Hover {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(1.19);
    -webkit-transform: scale(1.19); } }

@-webkit-keyframes scaleButtonDown {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8); } }

@keyframes scaleButtonDown {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8); } }

@keyframes closeButtonFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes closeButtonFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  @media only screen and (min-width: 600px) {
    .project-search-bar {
      margin: 0.75rem 0;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      justify-items: center; }
      .project-search-bar__cell {
        display: flex;
        margin: 1rem;
        padding: 0 0.5rem; }
      .project-search-bar__image {
        height: 80px;
        width: 80px;
        margin: 0.5rem;
        justify-self: center; }
        .project-search-bar__image:hover {
          cursor: pointer;
          opacity: 0.5; }
        .project-search-bar__image--active {
          animation: scaleButtonUp 1s cubic-bezier(0, 0.79, 0.6, 0.5) forwards;
          -webkit-animation: scaleButtonUp 1s cubic-bezier(0, 0.79, 0.6, 0.5) forwards; }
          .project-search-bar__image--active:hover {
            opacity: 1; }
        .project-search-bar__image--inactive {
          opacity: 0.5;
          animation: scaleButtonDown 1s cubic-bezier(0.22, 1, 0.36, 1) forwards;
          -webkit-animation: scaleButtonDown 1s cubic-bezier(0.22, 1, 0.36, 1) forwards; }
          .project-search-bar__image--inactive:hover {
            opacity: 1; }
        .project-search-bar__image--no-selected:hover {
          animation: scaleButtonUp-Hover 1s cubic-bezier(0, 0.79, 0.6, 0.5) forwards;
          -webkit-animation: scaleButtonUp-Hover 1s cubic-bezier(0, 0.79, 0.6, 0.5) forwards; }
      .project-search-bar .ant-btn {
        animation: closeButtonFadeIn ease 1.2s;
        -webkit-animation: closeButtonFadeIn ease 1.2s;
        -moz-animation: closeButtonFadeIn ease 1.2s;
        -o-animation: closeButtonFadeIn ease 1.2s;
        -ms-animation: closeButtonFadeIn ease 1.2s;
        margin-top: 0.75rem;
        right: 30px;
        z-index: 4; }
        .project-search-bar .ant-btn .anticon-close svg {
          margin-top: -7px; } }

.App {
  min-height: 100vh;
  width: 100%;
  display: grid;
  font-family: "Montserrat", sans-serif;
  background-color: #fdfcfc;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 60px;
  grid-template-areas: "header" "main-content" "footer"; }
  .App a:hover {
    text-decoration: none; }

