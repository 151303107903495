.header {
  grid-area: header;

  nav {
    @include title-gradient;
    padding: 20px;

    .nav-links {
      margin: 15px 0 8px 13px;
    }
  }

  a {
    font-family: $satisfy;
    font-size: 18pt;
    padding-top: 5px;

    &:hover {
      opacity: 0.4;
    }
  }

  #navbar-brand {
    img {
      height: 90px;
      width: 200px;
    }
  }

  @include multi-column-layout {
    .navbar-nav {
      margin-left: 30px;
    }

    .nav-links {
      display: flex;

      .navLink {
        padding: 0 5px 0 5px;
        margin-left: 3px;
      }
    }
  }
}
